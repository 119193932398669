.language-selector {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-select {
    background: rgba(255, 255, 255, 0.1); 
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.custom-select:focus {
    background: rgba(255, 255, 255, 0.2); 
    outline: none;
    border: 1px solid #ffffff; 
}

.custom-select option {
    background: #130164; 
    color: white;
}
