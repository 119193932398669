@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Exo', sans-serif;
}

.area {
    background: #040a77;
    background: linear-gradient(240deg, #130164, #38005e);
    width: 100%;
    height: 100%;
    position: relative; /* Mantener el fondo en su posición */
    overflow: hidden;
}

.circles {
    position: absolute;
    top: 0%; /* Limita los cuadrados para que no pasen sobre la imagen */
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.301);
    animation: animate 15s linear infinite;
    bottom: -50px;
}

/* Variaciones de tamaño y velocidad */
.circles li:nth-child(odd) {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.26);
    animation-duration: 7s;
}

.circles li:nth-child(even) {
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.2);
    animation-duration: 13s;
}

.area select {
    position: relative;
    z-index: 10;
}

/* Posiciones distribuidas */
.circles li:nth-child(1) { left: 5%; animation-delay: 0s; }
.circles li:nth-child(2) { left: 15%; animation-delay: 3s; }
.circles li:nth-child(3) { left: 25%; animation-delay: 6s; }
.circles li:nth-child(4) { left: 35%; animation-delay: 9s; }
.circles li:nth-child(5) { left: 45%; animation-delay: 12s; }
.circles li:nth-child(6) { left: 55%; animation-delay: 15s; }
.circles li:nth-child(7) { left: 65%; animation-delay: 18s; }
.circles li:nth-child(8) { left: 75%; animation-delay: 21s; }
.circles li:nth-child(9) { left: 85%; animation-delay: 24s; }
.circles li:nth-child(10) { left: 95%; animation-delay: 27s; }
.circles li:nth-child(11) { left: 10%; animation-delay: 2s; }
.circles li:nth-child(12) { left: 20%; animation-delay: 4s; }
.circles li:nth-child(13) { left: 30%; animation-delay: 6s; }
.circles li:nth-child(14) { left: 40%; animation-delay: 8s; }
.circles li:nth-child(15) { left: 50%; animation-delay: 10s; }
.circles li:nth-child(16) { left: 60%; animation-delay: 12s; }
.circles li:nth-child(17) { left: 70%; animation-delay: 14s; }
.circles li:nth-child(18) { left: 80%; animation-delay: 16s; }
.circles li:nth-child(19) { left: 90%; animation-delay: 18s; }
.circles li:nth-child(20) { left: 95%; animation-delay: 20s; }

/* Ajustar altura para que no cubran la imagen */
@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-600px) rotate(360deg);
        opacity: 0;
        border-radius: 50%;
    }
}

/* Asegurar que el contenido principal esté sobre los cuadrados */
.area > .row {
    position: relative;
    z-index: 1;
}
