.portfolio {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }

  .container {
    margin: 0 auto;
    padding: 0 20vh;
  }

  .profilePic{
    max-height: 350px;
    border-radius: 50%;
  }

  .hero {
    background: linear-gradient(135deg, #7108e0 0%, #00225e 100%);
    color: white;
    text-align: center;
    padding: 100px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-height: 200px;
  }

  .hero-content{
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  .hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .btn {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
  }

  .btn-primary {
    background-color: #fff;
    color: #6a11cb;
  }

  .btn-secondary {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
  }

  .btn:hover {
    opacity: 0.9;
  }

  section {
    padding: 80px 0;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    text-align: center;
  }

  .about {
    background-color: #f9f9f9;
  }

  .project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }

  .project-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .project-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .project-info {
    padding: 20px;
  }

  .project-info h3 {
    margin-bottom: 10px;
  }

  .project-link {
    display: inline-flex;
    align-items: center;
    color: #6a11cb;
    text-decoration: none;
    margin-top: 10px;
  }

  .project-link svg {
    margin-left: 5px;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
  }

  .skill-item {
    background-color: #fff;
    padding: 15px;
    min-width: fit-content;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.753);
  }

  .contact {
    background-color: #333;
    color: #fff;
  }

  .contact-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .contact-item svg {
    margin-right: 10px;
  }

  .social-links {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }

  .social-links a {
    color: #fff;
  }

  .contact-form {
    display: grid;
    gap: 20px;
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }

  .contact-form button {
    justify-self: start;
  }

  @media (max-width: 768px) {
    .contact-content {
      grid-template-columns: 1fr;
    }
}